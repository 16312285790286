import React from 'react';

export const DeniedPage = ({ msg }) => {
  return <Page>{msg ?? 'Sorry, you do not have access to this page.'}</Page>;
};

export const LoadingPage = () => {
  return <Page>Loading...</Page>;
};

export const ErrorPage = () => {
  return <Page>Sorry! There was an error loading this page</Page>;
};

const Page = ({ children }) => {
  const style = {
    padding: '3em',
    textAlign: 'center',
    width: 300,
    margin: 'auto',
  };
  return (
    <div style={style}>
      <h1>{children}</h1>
    </div>
  );
};
