import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { VerifyUser } from './VerifyUser';

/**
 * Extends React Router Dom's Route component to apply a permission check
 * Default functionality simply requires an authenticated user
 *
 * @param {function} userPassesTest a sync or async function that accepts a user and returns true if passed and false if not
 * @param {function} deniedComponent the component to display in case of permission denied
 * @param {function} errorComponent the component to display in case of an error
 * @param {function} loadingComponent the component to display while loading
 *
 */
export const ProtectedRoute = ({
  children,
  component: Component,
  userPassesTest, // default is just require user
  deniedMessage,
  deniedComponent,
  errorComponent,
  loadingComponent,
  ...rest
}) => {
  // A custom render method that checks test results first using `VerifyUser`
  const render = (props) => {
    const verifyProps = {
      children: Component ? <Component {...props} /> : children,
      userPassesTest,
      deniedMessage,
      deniedComponent,
      errorComponent,
      loadingComponent,
    };

    return <VerifyUser {...verifyProps} />;
  };

  return <Route {...rest} render={render} />;
};

ProtectedRoute.propTypes = {
  // The children to pass through to the underlying Route
  children: PropTypes.any,
  // The component to display (alternative to children)
  component: PropTypes.func,
  // A function that accepts a user and returns true if passed and false if not
  // If not provided, this just checks that the user is authenticated
  userPassesTest: PropTypes.func,
  // A message to show when the test fails
  deniedMessage: PropTypes.string,
  // Component to display if permission is denied
  deniedComponent: PropTypes.func,
  // Component to display while processing test function
  loadingComponent: PropTypes.func,
  // Component to display if an error occurs
  errorComponent: PropTypes.func,
};
