import React from 'react';
import PropTypes from 'prop-types';

import { useAuthTest } from '../hooks';
import { userExists } from '../utils';
import { DeniedPage, ErrorPage, LoadingPage } from '../pages';

/**
 * Applies a permission check on the current user using a test function
 * If test is not provided, simply requires an authenticated user
 *
 * @param {function} test a sync or async function that accepts a user and returns true if passed and false if not
 * @param {function} deniedComponent the component to display in case of permission denied
 * @param {function} errorComponent the component to display in case of an error
 * @param {function} loadingComponent the component to display while loading
 *
 */
export const VerifyUser = ({
  children,
  userPassesTest = userExists, // default is just require user
  deniedMessage,
  deniedComponent: DeniedComponent = DeniedPage, // optional
  errorComponent: ErrorComponent = ErrorPage, // optional
  loadingComponent: LoadingComponent = LoadingPage, //optional
}) => {
  const { passesTest, loading, error } = useAuthTest(userPassesTest);

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent error={error} />;
  if (!passesTest) return <DeniedComponent msg={deniedMessage} />;

  return children;
};

VerifyUser.propTypes = {
  // The children to display when passing
  children: PropTypes.any,
  // A function that accepts a user and returns true if passed and false if not
  userPassesTest: PropTypes.func,
  // A message to show when the test fails
  deniedMessage: PropTypes.string,
  // Component to display if the test fails
  deniedComponent: PropTypes.func,
  // Component to display while processing test function
  loadingComponent: PropTypes.func,
  // Component to display if an error occurs
  errorComponent: PropTypes.func,
};
