import { useCallback, useEffect, useRef, useState } from 'react';

import { useAuthUser } from './useAuthUser';
import { userExists } from '../utils';

/**
 * Runs a test function for the current user
 *
 * @param {function} userPassesTest the function to run against the user, result goes in `passesTest`
 */
export const useAuthTest = (userPassesTest = userExists) => {
  const { user, loading: loadingUser, error: userError } = useAuthUser();
  const [{ passesTest, loading, error }, setPassedTest] = useState({
    passesTest: false,
    loading: false,
    error: null,
  });
  const mounted = useRef();

  const runTest = useCallback(async () => {
    const setPassed = (state) => {
      // trap state changes after
      if (mounted.current) setPassedTest(state);
    };

    if (!loadingUser && !userError) {
      setPassed({ passesTest: false, loading: true, error: null });
      try {
        const testResult = await userPassesTest(user);
        setPassed({
          passesTest: testResult,
          loading: false,
          error: null,
        });
      } catch (err) {
        setPassed({ passesTest: false, loading: false, error: err });
      }
    }
  }, [user, loadingUser, userError, userPassesTest]);

  useEffect(() => {
    mounted.current = true;
    runTest();
    return () => (mounted.current = false);
  }, [runTest, user]);

  return {
    passesTest,
    loading: loading || loadingUser,
    error: error || userError,
  };
};
