import { useCallback, useEffect, useRef, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';

/**
 * Gets the currently authenticated users
 *
 * Updates the user on any auth event
 */
export const useAuthUser = () => {
  const [{ user, loading, error }, setUser] = useState({
    user: null,
    loading: false,
    error: null,
  });
  const mounted = useRef();

  const updateUser = useCallback(async () => {
    const updateUser = (state) => {
      // trap state changes after
      if (mounted.current) setUser(state);
    };

    updateUser((prev) => ({ ...prev, loading: true, error: false }));
    try {
      const session = await Auth.currentAuthenticatedUser();
      updateUser({
        user: session ? session : null,
        loading: false,
        error: false,
      });
    } catch (e) {
      // 'not authenticated' is expected. Other are not
      const error = e === 'not authenticated' ? null : e;
      updateUser({ user: null, loading: false, error });
    }
  }, []);

  useEffect(() => {
    mounted.current = true;
    updateUser();
    // Any Hub event will trigger the user update
    // NOTE: if these events can come in quick succession
    // or are duplicated we may decide to debounce this
    Hub.listen('auth', updateUser);
    return () => {
      mounted.current = false;
      Hub.remove('auth');
    };
  }, [updateUser]);

  return { user, loading, error };
};
