import React from 'react';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';

/**
 * A very thin wrapper around AmplifyAuthenticator for now
 * If we have consistent signup/login components and styles,
 * those can be added here.
 */
export const HalaAuthenticator = ({ children, ...rest }) => {
  return <AmplifyAuthenticator {...rest}>{children}</AmplifyAuthenticator>;
};
